// @ts-ignore
import { adminWebUrl } from '../../environment/environment';
import { Button, Col, Form, Input, Result, Row } from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { referrerRegistration } from '../../actions/business';
import { useSelector } from 'react-redux';
import CustomPhoneNumber from '@components/Comman/CustomPhoneNumber/CustomPhoneNumber';

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const ReferrerSignUp = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { isLoading } = useSelector((state: any) => state?.General);
    const { businessCode, businessName } = useParams();
    const query = new URLSearchParams(useLocation().search);
    const referrerCode = query.get('referrerCode'); 
    const [isSent, setIsSent] = useState(false);

    const onFinish = (values: any) => {
        const payload = {
            firstName: values?.firstName,
            lastName: values?.lastName,
            email: values?.email,
            mobileNumber: values?.mobileNumber,
            address: values?.address,
            password: values?.password,
            senderCode: businessCode.toUpperCase(),
            // referrerCode: values?.referrerCode,
        };

        dispatch(
            referrerRegistration(payload, (resp: any) => {
                if (resp?.status === 201 || resp?.status === 200) {
                    setIsSent(true)
                }
            })
        );
    };

    const onFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };


    return (
        <>
            {isSent ? <div style={{ marginTop: '200px' }}>
                <Result
                    status="success"
                    title={
                        <>
                            <div style={{ fontSize: '24px', fontWeight: 'bold', color: '#4CAF50' }}>
                                Registration Successful!
                            </div>
                            <div style={{ fontSize: '16px', color: '#555' }}>
                                Thank you for registering as a Referrer! 🎉
                            </div>
                            <div style={{ fontSize: '16px', color: '#555' }}>
                                Your login credentials have been sent to your email address.
                            </div>
                            <div style={{ marginTop: '20px', fontSize: '14px', color: '#777' }}>
                                Please check your inbox to proceed.
                            </div>
                        </>
                    }
                    // extra={[
                    //     <button className="btn btn-primary" key="login" onClick={()=> window.open(`${adminWebUrl}/login`, '_self')}>
                    //         LogIn
                    //     </button>,
                    //     <button className="btn btn-outline-primary" onClick={()=> navigate(-1)} key="login">
                    //         Go Back
                    //     </button>,
                    // ]}
                />
            </div>
                : <div className='authforms'>
                    <div className="authHeadings">
                        <button className='btn textAuthHeading' onClick={() => navigate(-1)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <rect x="0.4" y="0.4" width="23.2" height="23.2" rx="11.6" stroke="#F47523" strokeWidth="0.8" />
                                <path d="M14 8L10 12L14 16" stroke="#F47523" strokeWidth="1.25" strokeLinecap="round" />
                            </svg>
                        </button>
                        <div className="textAuthHeading">Referrer <span>SignUp</span></div>
                    </div>

                    <Row>
                        <Col lg={24} md={24} sm={24}>
                            <Form
                                className='formControl'
                                layout="vertical"
                                form={form}
                                {...layout}
                                onFinish={onFinish}
                                onFinishFailed={onFailed}
                                initialValues={{
                                    businessName: businessName.replace('_', ' '),
                                    // referrerCode: referrerCode
                                }}
                            >
                                {/* Business Code */}
                                <Form.Item
                                    name="businessName"
                                    className='formControlDesign'
                                    label="Business Name"
                                >
                                    <Input placeholder='Business Name' disabled={!!businessName} />
                                </Form.Item>
                                {/* First Name */}
                                <Form.Item
                                    name="firstName"
                                    className='formControlDesign'
                                    label="First Name"
                                    rules={[{ required: true, message: 'Please input your first name!' }]}
                                >
                                    <Input placeholder='First Name' />
                                </Form.Item>

                                {/* Last Name */}
                                <Form.Item
                                    name="lastName"
                                    className='formControlDesign'
                                    label="Last Name"
                                    rules={[{ required: true, message: 'Please input your last name!' }]}
                                >
                                    <Input placeholder='Last Name' />
                                </Form.Item>

                                {/* Email */}
                                <Form.Item
                                    name="email"
                                    className='formControlDesign'
                                    label="Email Address"
                                    rules={[{ required: true, type: 'email', message: 'Please input a valid email address!' }]}
                                >
                                    <Input placeholder='Email Address' />
                                </Form.Item>

                                <CustomPhoneNumber
                                    isRequired={true}
                                    isToolTip={false}
                                    label="Contact Number"
                                    name="mobileNumber"
                                />

                                {/* Address */}
                                <Form.Item
                                    name="address"
                                    className='formControlDesign'
                                    label="Address"
                                    rules={[{ required: false, message: 'Please input your address!' }]}
                                >
                                    <Input placeholder='Address' />
                                </Form.Item>

                                {/* Referrer Code */}
                                {/* {
                                    referrerCode &&
                                    <Form.Item
                                        name="referrerCode"
                                        className='formControlDesign'
                                        label="Referrer Code"
                                    >
                                        <Input placeholder='Referrer Code' disabled={!!referrerCode} />
                                    </Form.Item>
                                } */}

                                {/* Submit Button */}
                                <Button loading={isLoading} className='btn btn-primary w-100' type="primary" onClick={() => form.submit()}>
                                    Submit
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </div>
            }
        </>
    );
};

export default ReferrerSignUp;
